@font-face {
  font-family: 'KabelBd-Normal';
  src: local("KabelBd-Normal"), url(./assets/KabelBd-Normal.ttf) format('truetype');
}

@font-face {
  font-family: 'KabelStd';
  src: local("ITCKabelStd-Book"), url(./assets/ITCKabelStd-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Kabel-Black';
  src: local("Kabel-Black"), url(./assets/Kabel-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'KabelBdNormal';
  src: local("KabelBdNormal"), url(./assets/KabelBdNormal.ttf) format('truetype');
}

@font-face {
  font-family: 'KabelMedium';
  src: local("KabelMedium"), url(./assets/KabelMedium.ttf) format('truetype');
}

@font-face {
  font-family: 'Righteous-Regular';
  src: url(./assets/Righteous-Regular.ttf) format('truetype');
}

:root {
  --medium-rounded: 4px;
  --main-grid-width: 67%;
  --mobile-grid-width: 100%;
  --header-size: 40px;
  --nav-size:  24px;
  --super-rounded: 16px;
  --small-margin: 0.67rem;
  --sm-margin: 1.33rem;
  --reg-margin: 1.67rem;
  --medium-margin: 2.67rem;
  --color-bg: var(--main-bg-color);
  --color-primary: #0d7b67;
  --color-primary-light: #0d7b676b;
  --color-black: #1d1f22;;
  --bg-color: #ffffff;
  --alt-bg-color: #ffffff;
  --alt-white: rgb(255, 252, 248);
  --alt-white-transparent: rgb(255, 252, 248, 0.67);
  --color-red: #ff4c30ee;
  --search-card-shadow: rgba(168, 168, 168, 0.23) 0px 0px 6px -2px, rgba(126, 126, 126, 0.29) 0px 1px 6px -4px;
  --img-shadow: rgba(168, 168, 168, 0.49) 0px 0px 6px -2px, rgba(126, 126, 126, 0.49) 0px 2px 6px -2px;
  --button-shadow: rgba(201, 201, 201, 0.49) 0px 0px 2px -4px, rgba(172, 172, 172, 0.49) 0px 0px 2px -2px;
  --hover-shadow: rgba(201, 201, 201, 0.73) 0px 0px 6px -4px, rgba(172, 172, 172, 0.686) 0px 1px 4px -2px;
  --filter-btn-shadow: rgba(168, 168, 168, 0.49) 0px 0px 3px -10px, rgba(126, 126, 126, 0.49) 0px 1px 4px -8px;
  --input-shadow: rgb(239 239 239 / 49%) 0px 0px 7px -3px, rgb(246 246 246 / 49%) 0px 2px 7px -2px;
  --input-shadow-dark: rgba(47, 47, 47, 0.733) 0px 0px 4px -3px, rgba(62, 62, 62, 0.768) 0px 1px 4px -2px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
}
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  font-family: 'Kabel-Black';
  justify-content: space-between;
}

.a {
  font-size: var(--header-size);
  letter-spacing: -0.02rem;
}
.header-container span:hover {
  cursor: pointer;
}
.header-container nav {
  margin-right: var(--reg-margin);
  font-size: var(--nav-size);
  font-family: 'Righteous-Regular';
  text-transform: lowercase;
  color: initial;
}

nav:hover {
  cursor: pointer;
}
.menuIcon {
  background-size: contain;
  width: 2.15rem;
  height: 2.15rem;
  background-repeat: no-repeat;
}
/* .menuClosed {
  background-size: cover;
  width: 2.15rem;
  height: 2.15rem;
} */
.home-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 108px;
}
.home-grid img, .home-grid video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-grid img:hover {
  cursor: pointer;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 85%;
  width: 29%;
  margin-top: 20px;
  padding: 40px 80px;
  border-radius: 8px;
}

.contact-form label {
  padding-bottom: 5px;
}

.contact-form input, textarea {
  margin-bottom: 40px;
  border-radius: 2px;
  border: none;
  box-shadow: var(--button-shadow);
  padding: 0.67rem;
  border-radius: 8px;
}

.contact-form input[type="text"], input[type="email"]  {
  padding: 0.67rem;
  border-radius: 2px;
  border: none;
  box-shadow: var(--button-shadow);
  border-radius: 8px;
}

.contact-form input[type="submit"] {
  appearance: none;
  border: none;
  border-radius: 8px;
  padding: 0.97rem;
  box-shadow: var(--button-shadow);
  background-color: #ffffff;
  font-weight: 700;
  font-size: 12px;
}
input[type="text"]:hover, textarea:hover, input[type="email"]:hover {
  box-shadow: var(--hover-shadow);
}
input[type="submit"]:hover {
  cursor: pointer;
  box-shadow: var(--hover-shadow);
}

input:focus-within, textarea:focus-within {
  outline: 2px solid var(--color-black)
}

.contact-form textarea {
  min-height: 120px;
}

.contact-form label {
  font-size: 12px;
  font-weight: 700;
}

.teak-grid {
  display: grid;
  grid-template-columns: repeat(82, 1fr);
  grid-template-rows: repeat(100, 1fr);
  gap: 9px;
  margin-bottom: 9px;
  /* height: auto; */
  width: 100%;
}


.teak-grid img {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}

.teak-grid > img:nth-child(4n+1) {
  grid-column: 1 / span 44;
  grid-row: 1 /span 59;

}

.teak-grid > img:nth-child(4n+2) {
  grid-column: 45 / span 38;
  grid-row: 1 /span 52;
}

.teak-grid > img:nth-child(4n+3) {
  grid-column: 1 / span 44;
  grid-row: 60 /span 41;
}

.teak-grid > img:nth-child(4n+4) {
  grid-column: 45 / span 38;
  grid-row: 53 / span 48;
}

.mango-grid3x3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 9px;
  margin-bottom: 9px;
}

.mango-grid3x3 img {
  width: 100%;
}

.mango-gridAlt {
  display: grid;
  grid-template-columns: repeat(82, 1fr);
  grid-template-rows: repeat(100, 1fr);
  gap: 9px;
  margin-bottom: 9px;
  height: auto;
  width: 100%;
}

.mango-gridAlt img:nth-child(4n+1) {
  grid-column: 1 / span 35;
  grid-row: 1 / span 50;
}

.mango-gridAlt img:nth-child(4n+2) {
  grid-column: 36 / span 47;
  grid-row: 1 / span 30;
}

.mango-gridAlt img:nth-child(4n+3) {
  grid-column: 1 / span 35;
  grid-row: 51 / span 50;
}

.mango-gridAlt img:nth-child(4n+4) {
  grid-column: 36 / span 47;
  grid-row: 31 / span 70  ;
}

.mango-gridAlt img {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}

@media (prefers-color-scheme: dark) {
  .contact-form label {
    color: #fff;
  }
  .contact-form {
    background-color: rgba(74, 74, 74, 0.25);
  }
  .copyTitle, .copyBody {
    color: var(--alt-white)
  }
  .header-container a {
    color: var(--alt-white);
    text-decoration: none;
  }
  .App {
    background-color: var(--color-black);
  }
  .header-container {
    color: var(--alt-white);
  }
  .vertLine {
    background-color: var(--alt-white);
  }
  .menuIcon {
    background-image: url(./assets/menu_alt_05-white.svg);
  }
  .insta-icon {
    background-image: url(./assets/instagram-white.svg);
    background-size: contain;
    width: 1.33rem;
    height: 1.33rem;
    background-repeat: no-repeat;
    margin: 1rem 0 1rem 0;
  }
  .fb-icon {
    background-image: url(./assets/facebook-white.svg);
    background-size: contain;
    width: 1.33rem;
    height: 1.33rem;
    background-repeat: no-repeat;
    margin: 1rem 0 1rem 0;
  }
  .twitter-icon {
    background-image: url(./assets/twitter-white.svg);
    background-size: contain;
    width: 1.33rem;
    height: 1.33rem;
    background-repeat: no-repeat;
    margin: 1rem 0 1rem 0;
  }
}

@media (prefers-color-scheme: light) {
  .contact-form {
    background-color: hsl(56deg 17% 92% / 91%);
  }
  
  .header-container a {
    color: inherit;
    text-decoration: none;
  }
  .App {
    background-color: var(--bg-color);
  }
  .header-container {
    color: var(--color-black);
  }
  .vertLine {
    background-color: var(--color-black);
  }
  .menuIcon {
    background-image: url(./assets/menu_alt_05.svg);
  }
  .insta-icon {
    background-image: url(./assets/instagram.svg);
    background-size: contain;
    width: 1.33rem;
    height: 1.33rem;
    background-repeat: no-repeat;
    margin: 1rem 0 1rem 0;
  }
  .fb-icon {
    background-image: url(./assets/facebook.svg);
    background-size: contain;
    width: 1.33rem;
    height: 1.33rem;
    background-repeat: no-repeat;
    margin: 1rem 0 1rem 0;
  }
  .twitter-icon {
    background-image: url(./assets/twitter.svg);
    background-size: contain;
    width: 1.33rem;
    height: 1.33rem;
    background-repeat: no-repeat;
    margin: 1rem 0 1rem 0;
  }
}

@media only screen and (max-width: 376px) {
  
}

@media only screen and (max-width: 430px) and (min-width: 377px) { 

}

@media only screen and (max-width: 600px) {
  /* .header-container div {
    display: flex;
    flex-direction: row;
    padding-right: 5rem;
  }  */
  .last-yoga {
    object-fit: cover!important;
    height: 78vh!important;
  }
  .no-margin {
    margin-bottom: 0px!important;
  }
  .mobile-change {
    /* position: relative;
    top: -35vh;
    z-index: 0;
    opacity: 0.67; */
    display: none;
  }
  .work-open-main {
    display: flex;
    flex-direction: row;
    width: var(--mobile-grid-width);
  }
  .mobile-col {
    display: flex;
    flex-direction: column!important;
    width: var(--mobile-grid-width);
  }
  .video-main {
    justify-content: center;
    align-self: center;
    width: 100%;

    /* padding-left: 30%; */
  }
  .video-main-alt {
    justify-content: center;
    margin-bottom: 4px;
    width: 100%;
  }
  .video-main video {
    align-self: center;
    width: -webkit-fill-available;
  }
  .video-main-alt video {
    height: auto;
    width: 100%;
  }
  .left-work-grid {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-self: center; */
    width: 100%;
    /* height: 100vh;
    overflow-y: scroll; */
    
  }
  .grid2x2 {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  .grid2x2 img {
    width: 100%;
  }
  .grid3x3 {
    display: grid;
    grid-gap: 9px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    margin-bottom: 9px;
  }
  .grid3x3 img {
    width: 100%;
  }
  .imgGrid5 {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    width: 100%;
    grid-gap: 9px;
    overflow: hidden;
    margin-bottom: 9px
  }
  .imgGrid5 img {
    width: 100%;
    /* height: min-content; */
  }
  /* Assuming the order of the images are R,F,B,L,Headstock, we use a 5n+x notation, where n starts at 0. */

/* Main Image */
 .imgGrid5 > .show:nth-child(5n+5){
  grid-column: 5 / span 4;
  grid-row: 1 / span 8;
  /* overflow: hidden; */
  object-fit: cover;
}

 /* .imgGrid5 > .imgGrid5__item.show:nth-child(5n+2) img {
   object-fit: none;
   object-position: bottom;
 } */

/* Top Left Image */
.imgGrid5 > .show:nth-child(5n+1){
  grid-column: 1 / span 2;
  grid-row: 1 / span 5;
  overflow: hidden;
  object-fit: cover;
}

/* Top Middle Image */
.imgGrid5 > .show:nth-child(5n+2){
  grid-column: 3 / span 2;
  grid-row: 1 / span 5;
  overflow: hidden;
  object-fit: cover;
}

/* Bottom Middle (Small) Image */
.imgGrid5 > .show:nth-child(5n+4){
  grid-column: 4 / span 1;
  grid-row: 6 / span 3;
  overflow: hidden;
  object-fit: cover;
}

/* Bottom Left Image (portrait) */
.imgGrid5 > .show:nth-child(5n+3){
  grid-column: 1 / span 3;
  grid-row: 6 / span 3;
  overflow: hidden;
  object-fit: cover;
}
.gridSurf {
  display: grid;
  grid-template-columns: repeat(82, 1fr);
  grid-template-rows: repeat(100, 1fr);
  grid-gap: 9px;
  margin-bottom: 9px;
  height: 100%;
}
.gridSurf img {
  width: 100%;
  object-fit: cover;
  height: -webkit-fill-available;
}
/* left1 */
.gridSurf > img:nth-child(7n+1) {
  grid-column: 1 / span 49;
  grid-row: 1 / span 18;
}
.gridSurf > img:nth-child(7n+2) {
  grid-column: 50 / span 33;
  grid-row: 1 / span 25;
}
/* left2 */
.gridSurf > img:nth-child(7n+3) {
  grid-column: 1 / span 49;
  grid-row: 19 / span 41;
}
.gridSurf > img:nth-child(7n+4) {
  grid-column: 50 / span 33;
  grid-row: 26 / span 25;
}
/* left3 */
.gridSurf > img:nth-child(7n+5) {
  grid-column: 1 / span 49;
  grid-row: 60 / span 41;
}
.gridSurf > img:nth-child(7n+6) {
  grid-column: 50 / span 33;
  grid-row: 51 / span 25;
}
.gridSurf > img:nth-child(7n+7) {
  grid-column: 50 / span 33;
  grid-row: 76 / span 25;
}

  .work-pic-big-long {
    object-fit: contain;
    margin-bottom: 9px;
    height: 35vh;
    object-position: top;
  }
  .work-pic-grid {
    object-fit: contain;
    width: 100%;
  }
  .work-pic-big-wide {
    object-fit: contain;
    margin-bottom: 9px;
    height: 35vh;
    object-position: top;
  }
  .work-pic-big-cover {
    object-fit: cover;
    margin-bottom: 9px;
    transform: scale(1.2001);
    top: 45px;
    left: 4px;
    position: relative;
    
  }

  .copy-main {
    display: none;
  }

  .copy-main-mobile {
    /* padding-left: 0.87rem; */
    display: flex;
    flex-direction: column;
    width: 72%;
    justify-content: center;
    align-items: center;
    align-self: center;
    z-index: 9;
    margin-bottom: 9px;
  }
  .copyTitle {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .copyBody {
    font-size: 13px;
    font-weight: 500;
    /* text-align: left; */
    /* align-self: center; */

  }
  .text-center {
    text-align: center;
  }
  .menuIcon {
    margin-top: 3.33vh;
  }
  .menuClosed {
    margin-top: 3.33vh;
  }
 
  .header-container {
    padding-top: 3.33vh;
    width: var(--mobile-grid-width);
    margin-bottom: var(--sm-margin);

  }
  .home-grid {
    width: var(--mobile-grid-width);
    
    /* grid-auto-rows: 12rem; */
  }
  .nav-links a {
    font-size: 30px;
  }
  .nav-links nav {
    padding-bottom: 2rem;
  }
  /* .nav-links {
    animation: footerColor 0.40s ease;
  }
  @keyframes footerColor {
    from {
      opacity: 0;
      transform: translateX(45px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  } */
  .nav-links {
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    align-items: flex-end;
    /* padding: 5rem 0.67rem; */
    display: flex;
    z-index: 999999999;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 47vw;
  }
  .x-menu {
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 99999999999;
  }
  .header-container nav {
    font-size: 16px;
    /*display: none; */
  }
  .header-container span {
    padding-left: var(--reg-margin);
  }
  .right-hand-menu {
    display: flex;
    flex: 0;
    position: absolute;
    right: 0;
    padding-right: var(--reg-margin);
  }
  .insta-icon, .fb-icon, .twitter-icon {
    display: none;
  }
  .vertLine {
    /* margin: 0.67rem 0 0.67rem 0;
    height: 4.67rem;
    width: 2px; */
    display: none;
  }
  .volBtn {
    display: none;
  }
}

@media only screen and (min-width: 600px) { 
  .x-menu {
    display: none;
  }
  .x-menu svg {
    display: none;
  }
  .header-container div {
    display: flex;
    flex-direction: row;
    padding-right: var(--small-margin);
  }
  .aboutContainer {
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 75vh;
  }
  .aboutContainer h3 {
    font-weight: 200;
  }
  .mobile-hide, .mobile-show {
    display: none;
  }
  .menuIcon {
    margin-top: 4vh;
  }
  .menuClosed {
    margin-top: 4vh;
  }
  /* .mobile-change {
    display: none;
  } */
  .work-open-main {
    display: flex;
    flex-direction: row;
    width: calc(var(--mobile-grid-width) - 33%);
  }
  .video-main {
    justify-content: center;
    align-self: center;
    width: 100%;
    padding-left: 30%;
  }
  .video-main-alt {
    justify-content: center;
    margin-bottom: 4px;
    width: 100%;
  }
  .video-main video {
    align-self: center;
    height: 69vh;
  }
  .video-main-alt video {
    height: auto;
    width: 100%;
  }
  .left-work-grid {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 79%;
    /* height: 100vh;
    overflow-y: scroll; */
    
  }
  .grid2x2 {
    display: grid;
    grid-gap: 9px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  .grid2x2 img {
    width: 100%;
  }
  .grid3x3 {
    display: grid;
    grid-gap: 9px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    margin-bottom: 9px;
  }
  .grid3x3 img {
    width: 100%;
  }
  .imgGrid5 {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    /* width: 100%; */
    grid-gap: 9px;
    overflow: hidden;
    margin-bottom: 9px;
    /* height: 70vh; */
  }
  .imgGrid5 img {
    width: 100%;
    height: -webkit-fill-available;
  }
  /* Assuming the order of the images are R,F,B,L,Headstock, we use a 5n+x notation, where n starts at 0. */

/* Main Image */
 .imgGrid5 > .show:nth-child(5n+5){
  grid-column: 5 / span 4;
  grid-row: 1 / span 8;
  /* overflow: hidden; */
  object-fit: cover;
}

 /* .imgGrid5 > .imgGrid5__item.show:nth-child(5n+2) img {
   object-fit: none;
   object-position: bottom;
 } */

/* Top Left Image */
.imgGrid5 > .show:nth-child(5n+1){
  grid-column: 1 / span 2;
  grid-row: 1 / span 5;
  overflow: hidden;
  object-fit: cover;
}

/* Top Middle Image */
.imgGrid5 > .show:nth-child(5n+2){
  grid-column: 3 / span 2;
  grid-row: 1 / span 5;
  overflow: hidden;
  object-fit: cover;
}

/* Bottom Middle (Small) Image */
.imgGrid5 > .show:nth-child(5n+4){
  grid-column: 4 / span 1;
  grid-row: 6 / span 3;
  overflow: hidden;
  object-fit: cover;
}

/* Bottom Left Image (portrait) */
.imgGrid5 > .show:nth-child(5n+3){
  grid-column: 1 / span 3;
  grid-row: 6 / span 3;
  overflow: hidden;
  object-fit: cover;
}
.gridSurf {
  display: grid;
  grid-template-columns: repeat(82, 1fr);
  grid-template-rows: repeat(100, 1fr);
  grid-gap: 9px;
  margin-bottom: 9px;
  height: 100%;
}
.gridSurf img {
  width: 100%;
  object-fit: cover;
  height: -webkit-fill-available;
}
/* left1 */
.gridSurf > img:nth-child(7n+1) {
  grid-column: 1 / span 49;
  grid-row: 1 / span 18;
}
.gridSurf > img:nth-child(7n+2) {
  grid-column: 50 / span 33;
  grid-row: 1 / span 25;
}
/* left2 */
.gridSurf > img:nth-child(7n+3) {
  grid-column: 1 / span 49;
  grid-row: 19 / span 41;
}
.gridSurf > img:nth-child(7n+4) {
  grid-column: 50 / span 33;
  grid-row: 26 / span 25;
}
/* left3 */
.gridSurf > img:nth-child(7n+5) {
  grid-column: 1 / span 49;
  grid-row: 60 / span 41;
}
.gridSurf > img:nth-child(7n+6) {
  grid-column: 50 / span 33;
  grid-row: 51 / span 25;
}
.gridSurf > img:nth-child(7n+7) {
  grid-column: 50 / span 33;
  grid-row: 76 / span 25;
}

  .work-pic-big-long {
    object-fit: contain;
    margin-bottom: 9px;
  }
  .work-pic-grid {
    object-fit: contain;
    width: 100%;
  }
  .work-pic-big-wide {
    object-fit: contain;
    margin-bottom: 9px;
  }
  .work-pic-big-cover {
    object-fit: cover;
    margin-bottom: 9px;
    transform: scale(1.2001);
    top: 45px;
    left: 4px;
    position: relative;
    
  }
  .copy-main {
    padding-left: 0.87rem;
    display: flex;
    flex-direction: column;
    width: 21%;
  }
  .copy-main-mobile {
    display: none;
  }
  .copyTitle {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .copyBody {
    font-size: 14px;

  }
  .volBtn {
    /* position: absolute;
    z-index: 9999;
    min-width: 34px; 
    bottom: -4%;
    left: 34%;
    font-size: 100%!important;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: var(--alt-white-transparent);
    border-radius: 50px;
    padding: 0.67rem; */
    display: none;
  }
  .volBtn svg {
    padding: 0.25rem 0 0 0;
    align-self: center;
    /* position: absolute; */
  }
  
  .home-grid {
    width: var(--mobile-grid-width);
    /* grid-auto-rows: 11.167%; */
  }
  .header-container {
    padding-top: 4vh;
    width: var(--mobile-grid-width);
    margin-bottom: 2rem;
    
  }
  .header-container nav {
    /* display: none; */
    
  }
  .header-container span {
    padding-left: 2.67rem;
  }
  .right-hand-menu {
    display: flex;
    flex: 0;
    position: absolute;
    right: 0;
    padding-right: 2.67rem;
  }
  .insta-icon, .fb-icon, .twitter-icon {
    display: none;
  }
  .vertLine {
    /* margin: 0.67rem 0 0.67rem 0;
    height: 4.67rem;
    width: 2px; */
    display: none;
  }
  .nav-links {
    animation: footerColor 0.67s ease;
  }
  @keyframes footerColor {
    from {
      opacity: 0;
      transform: translateX(25px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  
}
@media only screen and (min-width: 992px) {
  .nav-links {
    animation: footerColor 0.67s ease;
  }
  @keyframes footerColor {
    from {
      opacity: 0;
      transform: translateX(25px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  .menuIcon {
    margin-top: 0;
  }
  .menuClosed {
    margin-top: 0;
  }
  .volBtn {
    position: absolute;
    z-index: 9999;
    /* height: 40px; */
    min-width: 34px; 
    bottom: -4%;
    left: 34%;
    font-size: 100%!important;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* right: 0; */
    background-color: var(--alt-white-transparent);
    border-radius: 50px;
    padding: 0.67rem;
  }
  .volBtn svg {
    padding: 0.25rem 0 0 0;
    align-self: center;
    /* position: absolute; */
  }
  
  .home-grid {
    width: var(--main-grid-width);
    /* grid-auto-rows: 11.167%; */
  }
  .right-hand-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 13.67vh;
    right: 7vw;
    height: min-content;
  }
  .insta-icon, .fb-icon, .twitter-icon, .vertLine {
    display: block  
  }

  .menuIcon {
    padding-bottom: 12.67rem;
  }
  .menuClosed {
    padding-bottom: 12.67rem;
  }
  .vertLine {
    margin: 0.67rem 0 0.67rem 0;
    height: 4.67rem;
    width: 2px;
  }
  .header-container span {
    padding-left: 0;
  }
  .header-container nav {
    display: block;
  }
  .header-container {
    padding-top: 14vh;
    width: var(--main-grid-width);
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1200px) { 

}

@media only screen and (min-width: 1700px) { 

}

@media only screen and (min-width: 2200px) { 

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

